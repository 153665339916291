
const digest = '1d007ee2897b4f523ebc86117f5303b97b0968ff55073937816646d4926087c2';
const css = `._header_lim26_1 {
  display: flex;
  width: 100%;
  background-color: var(--color-white);
  border-bottom: 2px solid var(--color--neutral-20);
}
@media only screen and (min-width: 960px) {
  ._header_lim26_1 {
    padding-right: calc(2em + 5px);
    padding-left: calc(11em + 11px);
    display: block;
  }
}
@media only screen and (min-width: 1100px) {
  ._header_lim26_1 {
    padding-left: calc(12em + 2px);
    display: block;
  }
}
@media only screen and (min-width: 1279px) and (max-width: 1472px) {
  .minibar ._header_lim26_1 {
    display: flex;
    padding-left: 0px;
    padding-right: calc(2rem + 9px);
  }
}
@media only screen and (min-width: 1472px) {
  ._header_lim26_1 {
    display: flex;
    padding-left: 0px;
    padding-right: calc(3rem + 9px);
  }
}
._headerProjects_lim26_34 {
  display: none;
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerProjects_lim26_34 {
    display: block;
    flex: 1 0 auto;
    border-right: 1px solid var(--color--neutral-20);
  }
}
@media only screen and (min-width: 1472px) {
  ._headerProjects_lim26_34 {
    display: block;
    flex: 1 0 auto;
    border-right: 1px solid var(--color--neutral-20);
  }
}
@media only screen and (min-width: 1600px) {
  .minibar ._headerProjects_lim26_34 {
    flex: 0 1 calc(25vw + 25px);
  }
}
._headerActivities_lim26_56 {
  display: none;
}
@media only screen and (min-width: 960px) {
  ._activity_lim26_60 {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerActivities_lim26_56 {
    flex-wrap: nowrap;
    display: block;
    width: 14em;
  }
}
@media only screen and (min-width: 1472px) {
  ._headerActivities_lim26_56 {
    flex-wrap: nowrap;
    display: block;
    width: 14em;
  }
}
@media only screen and (min-width: 1600px) {
  .minibar ._headerActivities_lim26_56 {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}
._headerTitle_lim26_86 {
  padding: 0.75em 0 0.75em 0.75em;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
}
._headerGroup_lim26_92 {
  display: flex;
  border-top: 1px solid var(--color--neutral-20);
  padding: 0.5em 0em;
  padding-right: 1.75em;
  margin: 0 -1em;
}
@media only screen and (min-width: 960px) {
  ._headerGroup_lim26_92 {
    padding: 0.75em;
    margin: 0em;
  }
}
._headerLabel_lim26_105 {
  color: var(--color-neutral-10);
  font-size: 0.75rem;
  line-height: 1;
}
._headerStart_lim26_110 {
  padding-left: 1.75em;
}
._headerValue_lim26_113 {
  margin-left: auto;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerValue_lim26_113 {
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1472px) {
  ._headerValue_lim26_113 {
    font-size: 0.875rem;
  }
}

/* Header Entries */
._headerEntries_lim26_131 {
  display: flex;
  width: 100%;
  padding: 0 1em;
}
@media only screen and (min-width: 960px) and (max-width: 1279px) {
  ._headerEntries_lim26_131 {
    padding-left: 10em;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerEntries_lim26_131 {
    width: auto;
    padding: 0;
    border-left: 2px solid var(--color--neutral-20);
    border-right: 2px solid var(--color--neutral-20);
  }
}
@media only screen and (min-width: 960px) {
  ._headerEntries_lim26_131 {
    width: auto;
    padding: 0;
    border-left: 2px solid var(--color--neutral-20);
    border-right: 2px solid var(--color--neutral-20);
  }
}
._headerEntry_lim26_157 {
  width: calc(100% / 7);
  border-right: 1px solid var(--color--neutral-20);
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerEntry_lim26_157 {
    min-width: 6em;
    border-right: 1px solid var(--color--neutral-20);
  }
}
@media only screen and (min-width: 1472px) {
  ._headerEntry_lim26_157 {
    min-width: 6em;
    border-right: 1px solid var(--color--neutral-20);
  }
}
._headerEntry_lim26_157:last-child {
  border-right: none;
}

/* Header entry weekdays, mobile */
._headerEntry_lim26_157 ._headerTitle_lim26_86:before {
  margin-right: 0.375em;
}
._headerEntry_lim26_157:nth-child(1) ._headerTitle_lim26_86:before {
  content: "M";
}
._headerEntry_lim26_157:nth-child(2) ._headerTitle_lim26_86:before {
  content: "T";
}
._headerEntry_lim26_157:nth-child(3) ._headerTitle_lim26_86:before {
  content: "W";
}
._headerEntry_lim26_157:nth-child(4) ._headerTitle_lim26_86:before {
  content: "T";
}
._headerEntry_lim26_157:nth-child(5) ._headerTitle_lim26_86:before {
  content: "F";
}
._headerEntry_lim26_157:nth-child(6) ._headerTitle_lim26_86:before {
  content: "S";
}
._headerEntry_lim26_157:nth-child(7) ._headerTitle_lim26_86:before {
  content: "S";
}
/* Header entry weekdays, desktop */
@media only screen and (min-width: 1472px) {
  ._headerEntry_lim26_157 ._headerTitle_lim26_86:before {
    margin-right: 0.25em;
  }
  ._headerEntry_lim26_157:nth-child(1) ._headerTitle_lim26_86:before {
    content: "Mon,";
  }
  ._headerEntry_lim26_157:nth-child(2) ._headerTitle_lim26_86:before {
    content: "Tue,";
  }
  ._headerEntry_lim26_157:nth-child(3) ._headerTitle_lim26_86:before {
    content: "Wed,";
  }
  ._headerEntry_lim26_157:nth-child(4) ._headerTitle_lim26_86:before {
    content: "Thu,";
  }
  ._headerEntry_lim26_157:nth-child(5) ._headerTitle_lim26_86:before {
    content: "Fri,";
  }
  ._headerEntry_lim26_157:nth-child(6) ._headerTitle_lim26_86:before {
    content: "Sat,";
  }
  ._headerEntry_lim26_157:nth-child(7) ._headerTitle_lim26_86:before {
    content: "Sun,";
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerEntry_lim26_157 ._headerTitle_lim26_86:before {
    margin-right: 0.25em;
  }
  .minibar ._headerEntry_lim26_157:nth-child(1) ._headerTitle_lim26_86:before {
    content: "Mon,";
  }
  .minibar ._headerEntry_lim26_157:nth-child(2) ._headerTitle_lim26_86:before {
    content: "Tue,";
  }
  .minibar ._headerEntry_lim26_157:nth-child(3) ._headerTitle_lim26_86:before {
    content: "Wed,";
  }
  .minibar ._headerEntry_lim26_157:nth-child(4) ._headerTitle_lim26_86:before {
    content: "Thu,";
  }
  .minibar ._headerEntry_lim26_157:nth-child(5) ._headerTitle_lim26_86:before {
    content: "Fri,";
  }
  .minibar ._headerEntry_lim26_157:nth-child(6) ._headerTitle_lim26_86:before {
    content: "Sat,";
  }
  .minibar ._headerEntry_lim26_157:nth-child(7) ._headerTitle_lim26_86:before {
    content: "Sun,";
  }
}
._headerEntryMonth_lim26_255 {
  display: none;
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerEntryMonth_lim26_255 {
    display: inline-block;
    margin-right: 0.25em;
  }
}
@media only screen and (min-width: 1472px) {
  ._headerEntryMonth_lim26_255 {
    display: inline-block;
    margin-right: 0.25em;
  }
}
._headerEntryDay_lim26_270 {
  color: var(--color--neutral-10);
  font-weight: 400;
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerEntryDay_lim26_270 {
    color: var(--color-neutral-40);
    font-weight: 600;
  }
}
@media only screen and (min-width: 1472px) {
  ._headerEntryDay_lim26_270 {
    color: var(--color-neutral-40);
    font-weight: 600;
  }
}
._headerEntryDate_lim26_286:after {
  /* content: ; */
}
@media only screen and (min-width: 1472px) {
  ._headerEntryDate_lim26_286:after {
    content: "";
  }
}
._headerTotals_lim26_294 {
  display: none;
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerTotals_lim26_294 {
    display: flex;
  }
}
@media only screen and (min-width: 1472px) {
  ._headerTotals_lim26_294 {
    display: flex;
  }
}
._headerTotalHours_lim26_307 {
  width: 3.5em;
  border-right: 1px solid var(--color--neutral-20);
}
@media only screen and (min-width: 1600px) {
  ._headerTotalHours_lim26_307 {
    width: 4.5em;
  }
}
._headerTotalExpenses_lim26_316 {
  width: 80px;
  border-right: 1px solid var(--color--neutral-20);
}

._headerView_lim26_321 {
  display: flex;
  width: 100%;
  background-color: var(--color-white);
  border-bottom: 2px solid var(--color--neutral-20);
}
@media only screen and (min-width: 960px) {
  ._headerView_lim26_321 {
    padding-right: calc(2em + 5px);
    padding-left: calc(11em + 11px);
    display: block;
  }
}
@media only screen and (min-width: 1100px) {
  ._headerView_lim26_321 {
    padding-left: calc(12em + 2px);
    display: block;
  }
}
@media only screen and (min-width: 1279px) and (max-width: 1472px) {
  .minibar ._headerView_lim26_321 {
    display: flex;
    padding-left: 0px;
    padding-right: 41px;
  }
}
@media only screen and (min-width: 1472px) {
  ._headerView_lim26_321 {
    display: flex;
    padding-left: 0px;
    padding-right: 57px;
  }
}
._headerViewTitle_lim26_354 {
  padding: 0.75em 0 0.75em 0.75em;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  border-bottom: 1px solid var(--color--neutral-20);
}
._headerViewGroup_lim26_361 {
  display: flex;
}
@media only screen and (min-width: 1279px) {
  .minibar ._headerViewGroup_lim26_361 {
    padding: 0.75em;
  }
}
@media only screen and (min-width: 1472px) {
  ._headerViewGroup_lim26_361 {
    padding: 0.75em;
  }
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"header":"_header_lim26_1","headerProjects":"_headerProjects_lim26_34","headerActivities":"_headerActivities_lim26_56","activity":"_activity_lim26_60","headerTitle":"_headerTitle_lim26_86","headerGroup":"_headerGroup_lim26_92","headerLabel":"_headerLabel_lim26_105","headerStart":"_headerStart_lim26_110","headerValue":"_headerValue_lim26_113","headerEntries":"_headerEntries_lim26_131","headerEntry":"_headerEntry_lim26_157","headerEntryMonth":"_headerEntryMonth_lim26_255","headerEntryDay":"_headerEntryDay_lim26_270","headerEntryDate":"_headerEntryDate_lim26_286","headerTotals":"_headerTotals_lim26_294","headerTotalHours":"_headerTotalHours_lim26_307","headerTotalExpenses":"_headerTotalExpenses_lim26_316","headerView":"_headerView_lim26_321","headerViewTitle":"_headerViewTitle_lim26_354","headerViewGroup":"_headerViewGroup_lim26_361"};
export { css, digest };
  