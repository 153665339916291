
const digest = '1cb72ae41f72c3d29b6a607d0bf4251c6447c9d4cdc4861099733599f289b21f';
const css = `._autoLayoutTop_p044t_1 {
  padding-top: 145px;
  padding-left: 160px;
  align-items: flex-center;
  justify-content: flex-center;
  align-self: initial;
  flex-grow: 0;
  flex-direction: column;
  display: flex;
  list-style-type: none;
  background-color: #fff;
  height: calc(100vh - 108px);
}

._heading6_p044t_15 {
  align-self: initial;
  flex-grow: 0;
  color: var(--color-brand);
  line-height: 1.3;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  padding-bottom: 1.75rem;
}

._autoLayoutCenter_p044t_27 {
  width: 900px;
  display: flex;
  align-items: center;
  align-self: initial;
  flex-grow: 0;
  flex-direction: row;
  margin-left: 0;
  margin-top: inherit;
  padding-bottom: 2rem;
  gap: 75px;
}

._flex_p044t_40 {
  flex-direction: column;
  align-self: initial;
  flex-grow: 0;
  flex: 0 0 auto;
  display: flex;
}

._heading1_p044t_48 {
  line-height: 1.3;
  font-size: 2.25rem;
  font-weight: 600;
  margin: 0;
}

._box_p044t_55 {
  align-self: initial;
  flex-grow: 0;
  padding: 0.75rem 0 1.5rem 0;
}

._text_p044t_61 {
  font-size: 1.125rem;
  color: #606d78;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

._link_p044t_69 {
  color: var(--color-brand);
  font-size: 1.125rem;
  font-weight: 600;
}

._button_p044t_75 {
  background-color: var(--color-brand);
  color: #fff;
  transition: background-color 0.2s ease 0s, border-color, color;
  border: none;
  border-radius: 0.25rem;
  padding: calc(calc(2.25rem - 1rem) * 0.5);
  font-weight: 600;
  font-size: 1rem;
}

._button_p044t_75:hover {
  background-color: #2f20a1;
}

._projectTemplatesImg_p044t_90 {
  width: 479px;
  height: 228px;
}

._modalFlex_p044t_95 {
  padding-top: 1.5rem;
  width: 50%;
  flex-direction: row;
  gap: 1rem;
  margin: 0 0.5rem;
}

._card_p044t_103 {
  border-radius: 6px;
  padding: 1rem;
  border: 1px solid #dfe2e4;
  overflow: hidden;
  position: relative;
}

._heading5_p044t_111 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3;
}

._detailText_p044t_117 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
}

._listText_p044t_123,
li {
  color: #606d78;
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  padding: 0;
}

._listItem_p044t_135 {
  list-style: inside;
  font-size: 0.875rem;
}

._cardBox_p044t_140 {
  padding: 0.5rem 0;
}

._cardBoxTrack_p044t_144 {
  padding-bottom: 1rem;
}

p {
  margin: 0;
  padding: 0;
}

._compareLink_p044t_153 {
  font-weight: 400;
  font-size: 0.875rem;
  color: #5840e0;
}

._cardFlex_p044t_159 {
  flex-direction: column;
  justify-content: center;
  padding-top: 0.75rem;
  display: flex;
  border-top: 1px solid #d1d5db;
}

._cardAutoLayout_p044t_167 {
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
  flex-direction: row;
  display: flex;
  list-style-type: none;
}

._seatMinimum_p044t_176 {
  text-align: center;
  color: #606d78;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2;
}

._badgeContainer_p044t_184 {
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  padding-top: 0.75rem;
  display: flex;
}

._badge_p044t_184 {
  background-color: #dfe2e4;
  color: #000;
  font-weight: 700;
  border-radius: 0.1875rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 0.125rem 0.25rem;
}

._cardContainer_p044t_204 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 -0.5rem;
}

._perMember_p044t_211 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #606d78;
}

._upgradeButton_p044t_217 {
  background-color: var(--color-brand);
  width: 100%;
  color: #fff;
  transition: background-color 0.2s ease 0s, border-color, color;
  border: none;
  border-radius: 0.25rem;
  padding: 8px 9px;
  font-weight: 600;
  font-size: 1rem;
}

._upgradeButton_p044t_217:hover {
  background-color: #2f20a1;
}

._saveAmountText_p044t_233 {
  color: #18a090;
  background-color: #ecfdf5;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 400;
}

._cardWrap_p044t_241 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 16px;
  bottom: 16px;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"autoLayoutTop":"_autoLayoutTop_p044t_1","heading6":"_heading6_p044t_15","autoLayoutCenter":"_autoLayoutCenter_p044t_27","flex":"_flex_p044t_40","heading1":"_heading1_p044t_48","box":"_box_p044t_55","text":"_text_p044t_61","link":"_link_p044t_69","button":"_button_p044t_75","projectTemplatesImg":"_projectTemplatesImg_p044t_90","modalFlex":"_modalFlex_p044t_95","card":"_card_p044t_103","heading5":"_heading5_p044t_111","detailText":"_detailText_p044t_117","listText":"_listText_p044t_123","listItem":"_listItem_p044t_135","cardBox":"_cardBox_p044t_140","cardBoxTrack":"_cardBoxTrack_p044t_144","compareLink":"_compareLink_p044t_153","cardFlex":"_cardFlex_p044t_159","cardAutoLayout":"_cardAutoLayout_p044t_167","seatMinimum":"_seatMinimum_p044t_176","badgeContainer":"_badgeContainer_p044t_184","badge":"_badge_p044t_184","cardContainer":"_cardContainer_p044t_204","perMember":"_perMember_p044t_211","upgradeButton":"_upgradeButton_p044t_217","saveAmountText":"_saveAmountText_p044t_233","cardWrap":"_cardWrap_p044t_241"};
export { css, digest };
  