
const digest = '539bb89a7c537d0579295e4a278d45a477225db80230c01de140d64f2d1503bc';
const css = `.schedule .body-content {
  padding: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {};
export { css, digest };
  