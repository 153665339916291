
const digest = '192a538bd9849b3d793c67a097a411408a55624b2fa4bfda98539cc45e2ead20';
const css = `/* Header component */
._header_z2vp8_2 {
  border-top: 1px solid var(--color--neutral-20);
  border-right: 1px solid var(--color--neutral-20);
  background: var(--color-white);
  z-index: 2;
  position: sticky;
  top: 0;
  overflow: visible;
}

._header_z2vp8_2._team_z2vp8_12 {
  border-right: none;
}

._profile_z2vp8_16 {
  align-items: center;
  border-bottom: 1px solid var(--color--neutral-20);
  color: var(--color-neutral-40);
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.75rem;
}

._hoursLabel_z2vp8_28 {
  line-height: 1rem;
  margin-left: 0.25rem;
  font-size: 0.625rem;
  color: var(--color-neutral-10);
}

._totals_z2vp8_35 {
  display: grid;
  grid-template-columns: 1fr 5rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  transform-origin: left center;
  overflow: visible;
}

._searchWrap_z2vp8_44 {
  overflow-x: hidden;
  overflow-y: visible;
  border-bottom: 2px solid var(--color--neutral-20);
  height: 2.125rem;
  position: relative;
}

._search_z2vp8_44 {
  margin-left: -1px;
  height: 2.125rem;
}

._projectSearch_z2vp8_57 {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  border-radius: 0;
  z-index: 5;
  height: 100%;
  display: flex;
  border: none;
  padding-left: 0.5rem;
  font-size: 0.75rem;
  background: transparent;
}

._add_z2vp8_72 {
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1;
  padding: 0.125rem 0.375rem;
  color: var(--color-neutral-10);
  text-transform: uppercase;
  background: var(--color-white);
  border: 1px solid var(--color--neutral-10);
  border-radius: 0.125rem;
  width: max-content;
  margin-left: auto;
  transition: all 0.2s var(--transition);
}

._add_z2vp8_72:hover {
  color: var(--color-neutral-10);
  border: 1px solid var(--color-neutral-40);
}

._projects_z2vp8_92 {
  color: var(--color-neutral-10);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding: 0.125rem 0;
}

._hours_z2vp8_28 {
  margin-left: auto;
  padding-right: 0rem;
  align-items: center;
  color: var(--color-neutral-40);
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 1;
  text-align: center;
}

._overheadContainer_z2vp8_115 {
  align-items: center;
  border-bottom: 1px solid var(--color--neutral-20);
  color: var(--color-neutral-40);
  display: flex;
  height: 33px;
  padding: 4px 4px 4px 7px;
}

._overheadBadgeContainer_z2vp8_124 {
  padding: 4px 2px;
  display: flex;
}

._overheadHours_z2vp8_129 {
  color: var(--color-neutral-30);
  font-size: 12px;
  margin-left: auto;
  padding-right: 9px;
}

._noOverheadText_z2vp8_136 {
  padding: 6px 2px 6px 6px;
  line-height: 1;
  color: var(--color-neutral-10);
  font-size: 12px;
}

._editLink_z2vp8_143 {
  cursor: pointer;
  margin-bottom: 1.5px;
  padding-left: 3.75px;
}

._editIcon_z2vp8_149 {
  height: 12px;
  width: 12px;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"header":"_header_z2vp8_2","team":"_team_z2vp8_12","profile":"_profile_z2vp8_16","hoursLabel":"_hoursLabel_z2vp8_28","totals":"_totals_z2vp8_35","searchWrap":"_searchWrap_z2vp8_44","search":"_search_z2vp8_44","projectSearch":"_projectSearch_z2vp8_57","add":"_add_z2vp8_72","projects":"_projects_z2vp8_92","hours":"_hours_z2vp8_28","overheadContainer":"_overheadContainer_z2vp8_115","overheadBadgeContainer":"_overheadBadgeContainer_z2vp8_124","overheadHours":"_overheadHours_z2vp8_129","noOverheadText":"_noOverheadText_z2vp8_136","editLink":"_editLink_z2vp8_143","editIcon":"_editIcon_z2vp8_149"};
export { css, digest };
  