
const digest = '43ea475bd6b98e34676d30fe8d41eb9a3e2565eaf722747ef0a064f6905ddad2';
const css = `._wrapper_hju8l_1 {
  display: flex;
  grid-column: 7 / span 1;
  height: 2.5rem;
  justify-self: flex-end;
  width: 5rem;
}

._gridView_hju8l_9 {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 0.75rem;
}

._gridView_hju8l_9 rect {
  fill: var(--color--neutral-10);
  transition: fill 0.2s ease-in-out;
}

._gridView_hju8l_9:hover rect {
  fill: var(--color-neutral-40);
}

._gridView_hju8l_9._active_hju8l_29 rect {
  fill: var(--color--neutral-10);
}

._button_hju8l_33 {
  border: 1px solid var(--color--neutral-10);
  border-radius: var(--border-radius);
  transition: all 0.2s var(--transition);
}

._button_hju8l_33:hover {
  border: 1px solid var(--color-neutral-40);
}

._button_hju8l_33:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

._button_hju8l_33:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  border-left: none;
}

._wrapper_hju8l_1:hover ._button_hju8l_33:not(:last-child) {
  border-right: 1px solid var(--color-neutral-40);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"wrapper":"_wrapper_hju8l_1","gridView":"_gridView_hju8l_9","active":"_active_hju8l_29","button":"_button_hju8l_33"};
export { css, digest };
  