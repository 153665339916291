
const digest = '623358c14ff0ef781a762f947f645e1645138fee1e83661093f80c4011881371';
const css = `._noProjectsOverlay_1c8t6_1 {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 51;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

._noProjects_1c8t6_1 {
  background: var(--color-white);
  padding: 2rem 1.5rem;
  width: 100%;
  max-width: 480px;
  box-shadow: var(--box-shadow);
  pointer-events: all;
}

._noProjects_1c8t6_1 h2 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1rem;
  margin: 0 0 2rem;
  color: var(--color-neutral-40);
}

._noProjects_1c8t6_1 p {
  color: var(--color-neutral-10);
}

._noProjects_1c8t6_1 a {
  color: var(--color-cta-blue);
}

._noProjects_1c8t6_1 a:not(._button_1c8t6_39):hover {
  text-decoration: underline;
}

._expampleImg_1c8t6_43 {
  max-width: 100%;
  border: 1px solid var(--color--neutral-20);
  border-radius: var(--border-radius);
}

._loadingColumn_1c8t6_49 {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid var(--color--neutral-20);
  height: 100%;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"noProjectsOverlay":"_noProjectsOverlay_1c8t6_1","noProjects":"_noProjects_1c8t6_1","button":"_button_1c8t6_39","expampleImg":"_expampleImg_1c8t6_43","loadingColumn":"_loadingColumn_1c8t6_49"};
export { css, digest };
  