
const digest = '61fe7e38d51dd2dfe1dadd9397a8fde4c7818002ad34464fff6c08a066da3c36';
const css = `._negative_phxb9_1 {
  color: var(--color-red);
}

._hoursLabel_phxb9_5 {
  font-size: 0.5rem;
  margin-left: 2px;
  line-height: 1;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"negative":"_negative_phxb9_1","hoursLabel":"_hoursLabel_phxb9_5"};
export { css, digest };
  